import React from "react";
import ServiceCard from "../components/ServiceCard";

const Services = () => {
  return (
    <section className="services bg-dark-100 pb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <ServiceCard
              number="01"
              imgDark="images/icon_box/d1.svg"
              imgLight="images/icon_box/d1-light.svg"
              title="Mimari Tasarım"
              text="Her projemizde, estetik ve fonksiyonelliği ön planda tutarak, kullanıcıların ihtiyaçlarına uygun, yaratıcı ve özgün tasarımlar geliştiriyoruz. Konut, ticari ve karma kullanımlı yapılar için çevreye duyarlı, sürdürülebilir ve teknolojiyle uyumlu çözümler üretiyoruz."
            />
          </div>
          <div className="col-lg-4">
            <ServiceCard
              number="02"
              imgDark="images/icon_box/d1.svg"
              imgLight="images/icon_box/d1-light.svg"
              title="Proje Planlama ve Yönetimi"
              text="Proje sürecinin başlangıcından sonuna kadar, detaylı planlama ve yönetim hizmetleri sunuyoruz. İhtiyaç analizleri, fizibilite çalışmaları, maliyet tahminleri ve zaman yönetimi gibi kritik adımları titizlikle ele alarak, projelerin sorunsuz ve verimli bir şekilde ilerlemesini sağlıyoruz."
            />
          </div>
          <div className="col-lg-4">
            <ServiceCard
              number="03"
              imgDark="images/icon_box/d1.svg"
              imgLight="images/icon_box/d1-light.svg"
              title="İnşaat Yönetimi"
              text="Yüksek kalite standartlarını gözeterek, dayanıklı, güvenli ve estetik yapılar inşa ediyoruz. İnşaat süreçlerinde malzeme seçiminden işçilik kalitesine kadar her detayı özenle planlıyor ve denetliyoruz. Projelerin zamanında ve bütçeye uygun bir şekilde tamamlanmasını sağlamak en büyük önceliğimizdir."
            />
          </div>
          <div className="col-lg-4">
            <ServiceCard
              number="04"
              imgDark="images/icon_box/d1.svg"
              imgLight="images/icon_box/d1-light.svg"
              title="İç Mimari ve Dekorasyon"
              text="İç mekan tasarımlarında, estetik ve fonksiyonelliği bir araya getirerek yaşam alanlarını daha keyifli ve konforlu hale getiriyoruz. Mekanların karakterini yansıtan, kullanıcıların yaşam tarzına uygun, zarif ve yenilikçi dekorasyon çözümleri sunuyoruz. Mobilya, aydınlatma, renk paleti ve malzeme seçimlerinde müşterilerimizin beklentilerini en üst düzeyde karşılıyoruz."
            />
          </div>
          <div className="col-lg-4">
            <ServiceCard
              number="05"
              imgDark="images/icon_box/d1.svg"
              imgLight="images/icon_box/d1-light.svg"
              title="3D Görselleştirme ve Sunum"
              text="Müşterilerimize projelerini daha iyi anlamaları için 3D modelleme ve görselleştirme hizmetleri sunuyoruz. Bu sayede, tasarımlarımızın her aşamasını şeffaf ve anlaşılır bir şekilde paylaşarak, müşterilerimizin projeye dair net bir fikir edinmelerini sağlıyoruz. 3D sunumlar, karar alma süreçlerini hızlandırır ve müşteri memnuniyetini artırır."
            />
          </div>
          <div className="col-lg-4">
            <ServiceCard
              number="06"
              imgDark="images/icon_box/d1.svg"
              imgLight="images/icon_box/d1-light.svg"
              title="Restorasyon ve Yenileme Projeleri"
              text="Tarihi yapıların restorasyonu ve mevcut yapıların yenilenmesi konusunda uzmanız. Tarihi dokuyu koruyarak, modern ihtiyaçlara cevap veren çözümler geliştiriyoruz. Bu süreçte, yapının orijinal kimliğini bozmadan, çağdaş tasarım anlayışını projeye entegre ediyoruz."
            />
          </div>
          <div className="col-lg-4">
            <ServiceCard
              number="07"
              imgDark="images/icon_box/d1.svg"
              imgLight="images/icon_box/d1-light.svg"
              title="Danışmanlık Hizmetleri"
              text="Mimarlık ve inşaat alanında ihtiyaç duyulan her türlü danışmanlık hizmetini sunuyoruz. Müşterilerimize projelerinin her aşamasında rehberlik ederek, en doğru kararları almalarına yardımcı oluyoruz. Bu süreçte, yasal gereklilikler, malzeme seçimi, sürdürülebilirlik ve maliyet optimizasyonu gibi konularda uzman görüşlerimizi paylaşıyoruz."
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
