import React from "react";
import Faqs from "../containers/Faqs";

const FrequentlyAskedQuestions = () => {
  return (
    <>
      <Faqs />
    </>
  );
};

export default FrequentlyAskedQuestions;
