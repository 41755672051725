import React from "react";
import SocialMedia from "../../components/SocialMedia";
import TeamProjectCard from "../../components/TeamElements/TeamProjectCard";
import WidgetTitle from "../../components/WidgetTitle";
import Bio from "../Bio";
import EducationalQualification from "../EducationalQualification";

const TeamDetailsContainer = () => {
  return (
    <main className="wrapper">

      <section className="team team-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-7 pe-lg-5">
              <div className="team-member-img">
                <img src="images/team/1.jpg" alt="img" />
              </div>
            </div>

            <div className="col-lg-5 col-md-12 ps-lg-0">
              <div className="team-member-information">
                <h5 className="name text-white">Rümeysa ADA KUBAL</h5>
                <h6 className="designation text-olive">YÜKSEK MİMAR</h6>
                <Bio
                  className="bio"
                  text=" Rümeysa Ada Kubal, 1994 doğumludur ve mimarlık eğitimine Fatih Sultan Mehmet Üniversitesi Mimarlık Fakültesi’nde başlamıştır. Eğitimine Yıldız Teknik Üniversitesi Fen Bilimleri Enstitüsü Mimari Tasarım Bölümü’nde devam etmiş ve yüksek lisans tezini &quot;Günümüz Yüksek Yapılarında Plan-Çekirdek İlişkisinin İşlev Olgusu&quot; üzerine tamamlamıştır. Lisans eğitimini 2018 yılında tamamladıktan sonra, İstanbul ve Düzce şehirlerinde çeşitli projelerde mimar olarak görev almıştır.
                         2020-2023 yılları arasında Düzce Belediyesi İmar ve Şehircilik Müdürlüğü’nde birçok tasarım ve projeye imza atan Ada Kubal, çeşitli seminerler, jüriler ve atölye çalışmalarına yürütücü olarak katılmıştır. 2023 yılında kurucu olduğu RAK Mimarlık firmasında tasarım faaliyetlerine devam eden  Ada Kubal, aynı zamanda Düzce Üniversitesi’nde mimari proje dersleri vermektedir."
                />


            {/* <SocialMedia />*/}
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default TeamDetailsContainer;
