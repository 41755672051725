import React from "react";
import Services from "../containers/Services";
import PageBanner from "../containers/PageBanner";

const OurServices = () => {
  return (
    <>
      <Services />
    </>
  );
};

export default OurServices;
